/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    IconButton
} from '@material-ui/core';
import DialogBox from '../Dashboard/Component/DialogBox';
import { showNotification } from '../App';
import { AppUser, AppUserData, DeleteEnquiryMutation, EnquiryDetailsQueryParams, fetchAppUserData, fetchEnquiryDetailsData, 
    fetchHomeCountry, fetchTenantData, FollowUpEnquiryMutation, OptionData, retrieveEnquiryDetailsData, TenantData } from './EnquirySummaryRepository';
import { client } from '..';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
// import { useHistory } from 'react-router-dom';
import { Enquiry, EnquiryData, EnquiryDetail, EnquirySummary } from './models/Enquiry';
import { AssignDialog } from './dialogs/AssignDialog';
import { FollowUpDialog } from './dialogs/FollowUpDialog';
import { CreateClientDialog } from './dialogs/CreateClientDialog';
import { EnquiryFormDrawer } from './drawers/EnquiryFormDrawer';
import { RvLoader } from '../components/Loader';
import moment from 'moment';
import { MessageAssigneeDialog } from './dialogs/MessageAssigneeDialog';
import CloseIcon from '@material-ui/icons/Close';
import { mainTheme } from '../Theme';
import RedoIcon from '@material-ui/icons/Redo';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import LocationSearchingOutlinedIcon from '@material-ui/icons/LocationSearchingOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
// import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import { MarkClientAndMatterCreatedDialog } from './dialogs/MarkClientAndMatterCreatedDialog';
import { PoBoxTypeData, retrievePOBoxTypeData } from '../lookupConfiguration/selectors/POBoxTypeSelector';
import { retrieveTimezoneOptionData, TimezoneOption, TimezoneOptionData } from '../lookupConfiguration/selectors/TimezoneSelector';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { HistoryFile } from './common/HistoryFile';
import { LeadDetailsHistoryData, LeadDetailsHistoryQueryParams, retrieveLeadDetailsHistoryData } from '../leadsDetail/LeadsDetailRepository';
import { CurrencySymbolData, retrieveCurrencySymbolData } from '../ClientAndContactDetails/referrals/ReferralsDetailsRepository';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        loaderWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        icon: {
            marginRight: '10px',
            color: mainTheme.BrandColors.TemplatePrimary
        },
        deleteIcon: {
            marginRight: '10px',
            color: 'rgb(220, 0, 78)',
        }
    })
);

export enum EnquiryTaskActionEnum {
    Assign = 1,
    FollowUp = 2,
    CreateClient = 3,
    Modify = 4,
    Delete = 5,
    EmailAssignee = 6,
    CompleteCreateClient = 7,
    ReOpen = 8,
    MessageAssignee = 9,
    AssignMeeting = 10,
}

interface EnquiryTaskActionProps {
    // tslint:disable-next-line: no-any
    data?: any;
    enquiryGuid: string;
}

interface EnquiryTaskActionState {
    homeCountry: string;
    sourceSystem: string;
    isDeleteConfirm: boolean;

    isEnquiryFormOpen: boolean;
    isFollowUpOpen: boolean;
    isAssignOpen: boolean;
    isCreateClientOpen: boolean;
    isMessageAssigneeOpen: boolean;
    isMarkClientMatterCreatedOpen: boolean;
    isAssignMeetingOpen: boolean;
    pOBoxType: string | null;
}

// tslint:disable-next-line: no-any
export const EnquiryTaskAction: React.FC<EnquiryTaskActionProps> = ( props ) => {
    const classes = useStyles();
    // const history = useHistory();    
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [enquiryData, setEnquiryData] = useState<Enquiry>();
    const [timezone, setTimezone] = useState<TimezoneOption[]>([]);
    const [currency, setCurrency] = useState<string>('$');
    
    const [state, setState] = useState<EnquiryTaskActionState>({
        homeCountry: '',
        sourceSystem: '',
        isDeleteConfirm: false,
        isEnquiryFormOpen: false,
        isFollowUpOpen: false,
        isAssignOpen: false,
        isCreateClientOpen: false,
        isMessageAssigneeOpen: false,
        isMarkClientMatterCreatedOpen: false,
        isAssignMeetingOpen: false,
        pOBoxType: null
    });
    const [appUser, setAppUser] = useState<AppUser>();  

    const enquiryGuid: string = props.enquiryGuid 
        ? props.enquiryGuid 
        : (props.data && props.data.guid) ? props.data.guid : undefined;

    useEffect(() => {
        fetchAppUserOnEffect();
        fetchPoBoxTypeData();
        fetchHomeCountryOnEffect();
        fetchTenantDataOnEffect();
        fetchTimezone();
        fetchCurrency();
    // tslint:disable-next-line: align
    }, []);

    const fetchCurrency = () => {
        retrieveCurrencySymbolData(
            false,
            // tslint:disable-next-line: no-any
            (data: any) => onDataCurrency(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
              showNotification(null, reason, 'error');
        });
    };

    const onDataCurrency = (data: CurrencySymbolData) => {
        setCurrency(data.options.currencySymbol.symbol);
    };

    const fetchPoBoxTypeData = () => {        
        retrievePOBoxTypeData(
            false,
            // tslint:disable-next-line: no-console
            (data) => onRetrievePOBoxType(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrievePOBoxType = (data: PoBoxTypeData) => {
        if (data.lookup.poBoxType.length > 0) {
            setState((prevState) => {
                return {
                    ...prevState,
                    pOBoxType: data.lookup.poBoxType[0].description
                };
            });
        }        
    };

    const fetchAppUserOnEffect = () => {
        fetchAppUserData(
            false,
            (data: AppUserData) => {
                handleAppUserFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch App User Settings', reason, 'error');
            }
        );
    };

    const handleAppUserFetch = (data?: AppUserData) => {
        if (
            data !== null &&
            data?.appUser !== undefined &&
            data.appUser.isAdministrator !== undefined
        ) {
            setAppUser((prevAppUser) => {
                // Object.assign would also work
                return {
                    ...prevAppUser,
                    userId: data.appUser.userId,
                    name: data.appUser.name,
                    isAdministrator: data.appUser.isAdministrator,
                    userName: data.appUser.userName
                };
            });
        }
    };

    const fetchHomeCountryOnEffect = () => {
        fetchHomeCountry(
            false,
            (data: OptionData) => {
                handleHomeCountryFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch Home Country Settings', reason, 'error');
            }
        );
    };

    const handleHomeCountryFetch = (optionData?: OptionData) => {
        if (
            optionData !== null &&
            optionData?.options !== undefined &&
            optionData.options.homeCountry !== undefined
        ) {
            setState((prevState) => {
                return {
                    ...prevState,
                    homeCountry: optionData.options.homeCountry.stringValue === null ? 'au' : optionData.options.homeCountry.stringValue,
                };
            });
        }
    };

    const fetchTenantDataOnEffect = () => {
        fetchTenantData(
            false,
            (data: TenantData) => {
                handleTenantDataFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch Tenant Data', reason, 'error');
            }
        );
    };
    
    const handleTenantDataFetch = (tenantsData?: TenantData) => {
        if (
            tenantsData !== null &&
            tenantsData?.tenant !== undefined &&
            tenantsData.tenant.sourceSystem !== undefined
        ) {
            setState((prevState) => {
                return {
                    ...prevState,
                    sourceSystem: tenantsData.tenant.sourceSystem
                };
            });
        }
    };

    const fetchTimezone = () => {
        retrieveTimezoneOptionData(
            false,
            // tslint:disable-next-line: no-console
            (data) => onRetrieveTimezoneData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveTimezoneData = (data: TimezoneOptionData) => {
        setTimezone(data.options.timezoneOption);
    };

    const handleToggle = () => {
        // tslint:disable-next-line: no-shadowed-variable
        setOpen(prevOpen => !prevOpen);
        setIsLoading(true);
        fetchEnquiryDetails();
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const fetchEnquiryDetails = () => {
        fetchEnquiryDetailsData(true, // This should always be a new request.
                                enquiryGuid,
                                (data: EnquiryData) => {
                                    handleEnquiryDataFetch(data);
                                }, 
                                (reason) => {
                                    showNotification('Failed to Fetch App User Settings', reason, 'error'); 
                                });
    };

    const handleEnquiryDataFetch = (data: EnquiryData) => {
        if (data && !data.loading && data.enquiry) {
            if (data.enquiry) {
                setEnquiryData(data.enquiry);

                setIsLoading(false);
            }
        }
    };

    const archorEvent = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    };

    const handleClickMenu = (menu: EnquiryTaskActionEnum) => (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        switch (menu) {
            case EnquiryTaskActionEnum.Assign : {
                setOpen(false);
                onAssignOpen(event);
                break;
            }
            case EnquiryTaskActionEnum.FollowUp : {
                setOpen(false);
                onFollowUpOpen(event);
                break;
            }
            case EnquiryTaskActionEnum.CreateClient : {
                setOpen(false);
                onCreateClientOpen(event);
                break;
            }
            case EnquiryTaskActionEnum.Modify: {
                setOpen(false);
                onModifyOpen(event);
                break;
            }
            case EnquiryTaskActionEnum.CompleteCreateClient : {
                setOpen(false);
                completeCreateClient();
                break;
            }
            case EnquiryTaskActionEnum.ReOpen : {
                setOpen(false);
                onReOpen(event);
                break;
            }
            case EnquiryTaskActionEnum.MessageAssignee : {
                setOpen(false);
                onMessageAssignee(event);
                break;
            }
            case EnquiryTaskActionEnum.AssignMeeting : {
                setOpen(false);
                onAssignMeeting(event);
                break;
            }
            default: {
                setOpen(false);
                break;
            }
        }
    };

    const completeCreateClient = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isMarkClientMatterCreatedOpen: true
            };
        });
        // client.mutate({
        //     mutation: CompleteCreateClientMutation,
        //     variables: { 
        //         input: getCompleteCreateClientInput()
        //     },
        // })
        // // tslint:disable-next-line: no-any
        // .then((results: { data: any }) => {
        //     if (results.data) {
        //         if (results.data.error === null || results.data.error === undefined) {
        //             showNotification(null, 'Successfully submitted', 'info');
        //         } else {
        //             showNotification('Complete Create Client Failed', results.data.error, 'error');
        //         }
        //     }
        // })
        // // tslint:disable-next-line:no-any
        // .catch((reason: any) => {
        //     showNotification('Complete Create Client Failed', reason, 'error');
        // });
    };

    // const getCompleteCreateClientInput = () => {
    //     return {
    //         enquiryGuid: enquiryGuid
    //     };
    // };

    const onAssignOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isAssignOpen: true
        });
    };

    const onFollowUpOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isFollowUpOpen: true
        });
    };

    const onMessageAssignee = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isMessageAssigneeOpen: true
        });
    };

    const onAssignMeeting = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isAssignMeetingOpen: true
        });
    };

    const onCreateClientOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isCreateClientOpen: true
        });
    };

    const onReOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        reOpenEnquiry();        
    };

    const reOpenEnquiry = () => {
        client.mutate({
            mutation: FollowUpEnquiryMutation,
            variables: {
                input: getReOpenFollowUpInput()
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any; }) => { 
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info'); 
                } else {
                    showNotification('Failed to Re-Open Enquiry', results.data.error, 'error'); 
                }
            }            
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { showNotification('Failed to Re-Open Enquiry', reason, 'error'); });        
    };  

    const getReOpenFollowUpInput = () => {
        return {
            enquiryGuid: enquiryGuid,
            followUpAction: 'Re-Open Enquiry',
            followUpActionSystemName: 'ReOpenEnquiry',
            followUpDate: moment().toDate(),
            outcome: 'More Follow Up',
            outcomeDate: null,
            reason: null,
            reasonGuid: null,
            followUpNotes: null,
        };
    }; 

    const onModifyOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        setState({
            ...state,
            isEnquiryFormOpen: true
        });
    };

    const onDialogClose = () => {
        setOpen(false);

        setState({
            ...state,
            isAssignOpen: false,
            isFollowUpOpen: false,
            isCreateClientOpen: false,
            isEnquiryFormOpen: false,
            isDeleteConfirm: false,
            isMessageAssigneeOpen: false,
            isMarkClientMatterCreatedOpen: false,
            isAssignMeetingOpen: false
        });
    };

    const handleDelete = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isDeleteConfirm: true
            };
        });
    };

    const onDeleteConfirm = (agree: boolean) => {
        if (agree) {
            client.mutate({
                mutation: DeleteEnquiryMutation,
                variables: {
                    guid: enquiryGuid
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                    } else {
                        showNotification('Failed to Save Enquiry', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Enquiry', reason, 'error');
            });
        }

        setState((prevState) => {
            return {
                ...prevState,
                isDeleteConfirm: false
            };
        });
    };

    // const handleView = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);
    //     setOpen(false);

    //     var href = history.createHref({
    //         pathname: `/enquiriesDetail/${enquiryGuid}`
    //     });

    //     window.open(href, '_blank');
    // };

    const onHistoryPrint = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);
        
        var enquiryQueryParams: EnquiryDetailsQueryParams = {
            guid: (props.data as EnquirySummary).guid,
        };

        retrieveEnquiryDetailsData(
            enquiryQueryParams,
            true,
            (data) => onDataRetrieved(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const onDataRetrieved = (data: EnquiryData) => {

        const detail = data.enquiry.detail;

        var leadQueryParams: LeadDetailsHistoryQueryParams = {
            offset: null,
            first: null,
            filter: null,
            sortColumn: 'UpdatedDate',
            sortDirection: 'DESC',
            entityGuidID: (props.data as EnquirySummary).guid,
        };
        
        retrieveLeadDetailsHistoryData(
            leadQueryParams,
            true,
            // tslint:disable-next-line: no-any no-shadowed-variable
            (data: any) => onEnquiryHistoryDataRetrieved(data, detail),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
        
    };

    const onEnquiryHistoryDataRetrieved = (data: LeadDetailsHistoryData, detail: EnquiryDetail) => {
         HistoryFile(
            detail, 
            'DESC', 
            currency, 
            timezone, 
            data.task.summaryList,
            (results) => {
                // tslint:disable-next-line: no-console
                var url = URL.createObjectURL(results);
                window.open(url, '_blank', 'noopener, noreferrer');
            }
        );
    };

    /*
    const onEmailAssignee = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);
        setOpen(false);

        var mail = document.createElement('a');
        // tslint:disable-next-line:max-line-length
        mail.href = `mailto:${enquiryData?.detail.assignedToEmailAddress}?subject=RE ${enquiryData?.detail.firstName} 
        ${enquiryData?.detail.lastName}, new business enquiry received ${moment(enquiryData?.detail.initialEnquiryDate).format('DD MMM YYYY')}`;
        mail.click();
    };
    */

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
        // tslint:disable-next-line: align
    }, [open]);

    const getEnquiryTaskActions = () => {
        if (enquiryData && appUser) {
            return (
                <React.Fragment>
                    {state.isAssignOpen && (
                        <AssignDialog
                            guid={enquiryGuid}
                            assignedBy={appUser?.userId}
                            assignedByName={appUser?.name}
                            onDialogClose={onDialogClose}
                        />
                    )}
                    
                    {state.isFollowUpOpen && (
                        <FollowUpDialog
                            guid={enquiryGuid}
                            onDialogClose={onDialogClose}
                            firstName={enquiryData.detail.firstName}
                            lastName={enquiryData.detail.lastName}
                            email={enquiryData.detail.email}
                            canCreateMatter={enquiryData.actions.canCreateMatter}
                        />   
                    )}

                    {state.isAssignMeetingOpen && (
                        <FollowUpDialog
                            guid={enquiryGuid}
                            onDialogClose={onDialogClose}
                            firstName={enquiryData.detail.firstName}
                            lastName={enquiryData.detail.lastName}
                            email={enquiryData.detail.email}
                            canCreateMatter={enquiryData.actions.canCreateMatter}
                            followUpAction="Book a Meeting"
                            followUpActionSystemName="BookMeeting"
                            followUpSystemAction="BookMeeting"
                        />   
                    )}

                    {/* We are doing an internal Note here. So it is a Follow Up Action  */}
                    {state.isMessageAssigneeOpen && (
                        <MessageAssigneeDialog
                            guid={enquiryGuid}
                            onDialogClose={onDialogClose}
                        />   
                    )}
                    
                    {state.isCreateClientOpen && (
                        <CreateClientDialog 
                            anchor="right"
                            guid={enquiryGuid}
                            homeCountry={state.homeCountry}
                            sourceSystem={state.sourceSystem}
                            onDialogClose={onDialogClose}
                            title={enquiryData.detail.title}
                            firstName={enquiryData.detail.firstName}
                            lastName={enquiryData.detail.lastName}
                            organisation={enquiryData.detail.organisation}
                            email={enquiryData.detail.email}
                            mobile={enquiryData.detail.mobile}
                            phone={enquiryData.detail.phone}
                            sourceNotes={enquiryData.detail.sourceNotes}
                            source={enquiryData.detail.source}
                            enquiryActions={enquiryData.actions}
                            enquiryDetail={enquiryData.detail}
                            poBoxType={enquiryData.detail.pOBoxType ? enquiryData.detail.pOBoxType : state.pOBoxType}
                            timezone={timezone}
                        />
                    )}
                    
                    {state.isEnquiryFormOpen && (
                        <EnquiryFormDrawer                         
                            guid={enquiryGuid}
                            onDialogClose={onDialogClose}
                            formAction={'edit'}
                            assignedBy={appUser.userId}
                            assignedByName={appUser.name} 
                            isEnquiryFormOpen={true}  
                            isKeyOpportunity={enquiryData.detail.isKeyOpportunity}
                            homeCountry={state.homeCountry}
                            pOBoxType={state.pOBoxType}
                        />
                    )}  
                    {state.isMarkClientMatterCreatedOpen && (
                        <MarkClientAndMatterCreatedDialog 
                            guid={enquiryGuid}
                            onDialogClose={onDialogClose}
                            canCreateMatter={enquiryData.actions.canCreateMatter}
                        />
                    )}
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    const getLoader = () => {
        return (
            <div className={classes.loaderWrapper}>
                <RvLoader />
            </div>
        );
    };

    const isCreateClientEnabled = enquiryData?.actions.canCreateClient
        ? true
        : enquiryData?.actions.canCreateContactForLostEnquiry ? true : false;

    const getMenuItems = () => {
        if (enquiryData) {
            return (
                <React.Fragment>  
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition={true}
                        disablePortal={false}
                        style={{
                            marginLeft: '100px'                                                       
                        }}   
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                }}
                            >  
                                <React.Fragment>
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                {UserFeature.HasAccess(UserFeatureEnum.hasAssignLead) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.Assign)}
                                                        disabled={!enquiryData.actions.canAssign}
                                                    >
                                                        <AssignmentIndOutlinedIcon fontSize="small" className={classes.icon} /> Assign
                                                    </MenuItem>
                                                )}
                                                
                                                {UserFeature.HasAccess(UserFeatureEnum.hasAssignLead) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.MessageAssignee)}
                                                        disabled={!enquiryData.actions.canMessageAssignee}
                                                    >
                                                        <ContactMailOutlinedIcon fontSize="small" className={classes.icon} /> Message Assignee
                                                    </MenuItem>
                                                )}

                                                {UserFeature.HasAccess(UserFeatureEnum.hasFollowUpLead) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.FollowUp)}
                                                        disabled={!enquiryData.actions.canFollowUp}
                                                    >
                                                        <LocationSearchingOutlinedIcon fontSize="small" className={classes.icon} /> Follow Up
                                                    </MenuItem>
                                                )}

                                                {UserFeature.HasAccess(UserFeatureEnum.hasFollowUpLead) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.AssignMeeting)}
                                                        disabled={!enquiryData.actions.canFollowUp}
                                                    >
                                                        <AssignmentOutlinedIcon fontSize="small" className={classes.icon} /> Assign Meeting
                                                    </MenuItem>
                                                )}

                                                {UserFeature.HasAccess(UserFeatureEnum.hasCreateClient) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.CreateClient)}
                                                        disabled={!isCreateClientEnabled}
                                                    >
                                                        <PersonAddOutlinedIcon fontSize="small" className={classes.icon} /> 
                                                        {enquiryData.actions.canCreateContactForLostEnquiry 
                                                            ? 'Create Contact'
                                                            : `Create ${enquiryData.actions.canCreateMatter ? 'Matter' : 'Client'}`
                                                        }
                                                    </MenuItem>
                                                )}

                                                {UserFeature.HasAccess(UserFeatureEnum.hasMarkAsClientCreated) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.CompleteCreateClient)}
                                                        disabled={!enquiryData.actions.canCreateClient}
                                                    >
                                                        <BeenhereOutlinedIcon fontSize="small" className={classes.icon} /> {`Mark as ${enquiryData.actions.canCreateMatter ? 'Matter' : 'Client'} Created`}
                                                    </MenuItem>
                                                )}

                                                {UserFeature.HasAccess(UserFeatureEnum.hasFollowUpLead) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.ReOpen)}
                                                        disabled={!enquiryData.actions.canReOpen}
                                                    >
                                                        <RedoIcon fontSize="small" className={classes.icon} /> Re-Open
                                                    </MenuItem>
                                                )}

                                                {UserFeature.HasAccess(UserFeatureEnum.hasModifyLead) && (
                                                    <MenuItem
                                                        onClick={handleClickMenu(EnquiryTaskActionEnum.Modify)}
                                                        disabled={!enquiryData.actions.canModify}
                                                    >
                                                        <EditOutlinedIcon fontSize="small" className={classes.icon} /> Modify
                                                    </MenuItem>
                                                )}

                                                {UserFeature.HasAccess(UserFeatureEnum.hasDeleteLead) && (
                                                    <MenuItem
                                                        onClick={() => handleDelete()}
                                                    >
                                                        <CloseIcon fontSize="small" className={classes.deleteIcon} /> Delete
                                                    </MenuItem>
                                                )}

                                                {/* {UserFeature.HasAccess(UserFeatureEnum.hasLeadsDetail) && (
                                                    <MenuItem
                                                        onClick={(event) => handleView(event)}
                                                    >
                                                        <HistoryOutlinedIcon fontSize="small" className={classes.icon} /> History
                                                    </MenuItem>
                                                )} */}

                                                <MenuItem
                                                    onClick={(event) => onHistoryPrint(event)}
                                                >
                                                    <PrintOutlinedIcon fontSize="small" className={classes.icon} /> History Report
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>                                                    
                                    </Paper>
                                </React.Fragment>                        
                            </Grow>
                        )}
                    </Popper>
                </React.Fragment> 
            );
        } else {
            return null;
        }
    };

    return (
        <div className={classes.root}>
            {state.isDeleteConfirm && (
                <DialogBox
                    title="Delete Enquiry"
                    // tslint:disable-next-line:max-line-length
                    content={`Are you sure to delete Enquiry: ${enquiryData?.detail.name ? enquiryData.detail.name : ''}?`}
                    show={state.isDeleteConfirm}
                    isAgree={onDeleteConfirm}
                    disAgreeLabel={'No'}
                    agreeLabel={'Yes'}
                />
            )}
            <IconButton
                color="primary"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <MoreHorizOutlinedIcon />
            </IconButton>

            {
                isLoading
                ? getLoader()
                : getMenuItems()                  
            }

            {getEnquiryTaskActions()} 
        </div>
    );
};
