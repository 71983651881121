import * as React from 'react';

export const TimerContext = React.createContext({});

interface TimerContextProps {}

interface TimerContextState {
    isTimerOpen: boolean;
    totalTimer: number;
}

class TimerContextProvider extends React.Component<TimerContextProps, TimerContextState> {
    constructor(props: TimerContextProps, state: TimerContextState) {
        super(props, state);

        // this.toggleTimerDrawer = this.toggleTimerDrawer.bind(this);
        // this.tollgeTimerDrawerClosed = this.tollgeTimerDrawerClosed.bind(this);
        this.setTimerTotal = this.setTimerTotal.bind(this);
        this.setInitialTimerTotal = this.setInitialTimerTotal.bind(this);

        this.state = {
            isTimerOpen: false,
            totalTimer: 0,
        };
    }

    // toggleTimerDrawer() {
    //     this.setState({ isTimerOpen: !this.state.isTimerOpen });
    // }

    // tollgeTimerDrawerClosed() {
    //     this.setState({ isTimerOpen: false });
    // }

    setTimerTotal(total: number, operation: 'minus' | 'add') {
        if (operation === 'add') {
            this.setState({
                totalTimer: this.state.totalTimer + 1,
            });
        } else {
            this.setState({
                totalTimer: this.state.totalTimer - 1,
            });
        }
    }

    setInitialTimerTotal(total: number) {
        this.setState({
            totalTimer: total
        });
    }

    render() {
        return (
            <TimerContext.Provider
                value={{
                    ...this.state,
                    // toggleTimerDrawer: this.toggleTimerDrawer,
                    // tollgeTimerDrawerClosed: this.tollgeTimerDrawerClosed,
                    setTimerTotal: this.setTimerTotal,
                    setInitialTimerTotal: this.setInitialTimerTotal
                }}
            >
                {this.props.children}
            </TimerContext.Provider>
        );
    }
}

export default TimerContextProvider;
