import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '..';

export enum EmailTemplateTypeEnum {
    MFA = 0,
    NEW_USER_CREATED,
    LEAP_CONTACT_US,
    PASSWORD_RESET,
    LEAP_FIRM_PROVISIONING,
    NEW_INTERNAL_USER_CREATED,
    NEW_MATTER_MESSAGE_SENT,
    NEW_DOCUMENT_UPLOADED,
    NEW_LEAD_ASSIGNED,
    OTHER,
    WELCOME_EMAIL,
    NEW_LEAD,
    NEW_CLIENT = 12,
    NEW_MATTER_DOCUMENT = 13,
    ENQUIRY_MENTIONS = 14,
    TASK_ASSIGNED = 15,
    TASK_COMPLETED = 16,
    PHONE_MESSAGE,
    NEW_ENQUIRY_RESPONSE = 18,
    MEETING_BOOKING = 19,
    EMAIL_RESPONSE = 20,
    FOLLOW_UP_ENQUIRY_REMINDER = 21,
    FOLLOW_UP_ENQUIRY_OVER_DUE_REMINDER = 22,
    NEW_TENANT_PROVISIONED = 23,
    TENANT_DE_PROVISIONED = 24,
    NEW_MEETING_NOTIFICATION = 25,
    RecordCreatedConfirmation = 26,
    RecordCreationFailed = 27,
    TaskMessage = 28,
    TASK_REMINDER = 29,
    TASK_OVER_DUE_REMINDER = 30,
    InstructMatter  = 31,
    UnassignedEnquiry = 32,
    EmailSendFailureNotification = 33
}

export interface EmailTemplateQueryParams {
    isInternalRole: boolean | null;
    roleId: string | null;
    selectedUserId: string | null; 
    emailType: string | null;
}

export interface EmailTemplateData {
    loading?: boolean;
    networkStatus?: number;
    templates: Templates;
}

export interface Templates {
    emailTemplate: EmailTemplate;
}

export interface EmailTemplate {
    emailTemplateGuid: null;
    emailType:         number;
    isInternalRole:    null;
    roleGuid:          null;
    subject:           string;
    template:          string;
    templateType:      null;
    userGuid:          null;
    ccAssistant:       boolean;
    matchedPropertys:  MatchedPropertys;
}
export interface MatchedPropertys {
    entity:          string;
    matchedProperty: MatchedProperty[];
}
export interface MatchedProperty {
    key:                  string;
    keyDescription:       string;
    recipient:            string;
    recipientDescription: string;
    scope:                string;
    scopeDescription:     string;
    source:               string;
    sourceDescription:    string;
    location:    string;
    locationDescription:    string;
    assignee:    string;
    assigneeDescription:    string;
}

export function retrieveEmailTemplateData(
    query: EmailTemplateQueryParams, 
    refreshData: boolean,
    onSuccess: (data: EmailTemplateData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
     : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
        client.query({
            query: EmailTemplateQuery,
            variables: {
                isInternalRole: query.isInternalRole,
                roleId: query.roleId,
                selectedUserId: query.selectedUserId,
                emailType: query.emailType
            },
            fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { 
        onError(reason); 
    });
}

export const UpdateEmailTemplate = gql`
    mutation UpdateEmailTemplate(
        $emailType: EmailType, 
        $isInternalRole: Boolean, 
        $roleId: String,
        $selectedUserId: String, 
        $template: String, 
        $subject: String,
        $matchedProperties: MatchedPropertiesInput,
        $ccAssistant: Boolean
    ){
        updateEmailTemplate(
            emailType: $emailType, 
            isInternalRole: 
            $isInternalRole, 
            roleId: $roleId, 
            selectedUserId: 
            $selectedUserId, 
            template: $template, 
            subject: $subject,
            matchedProperties: $matchedProperties,
            ccAssistant: $ccAssistant
        )
    }
`;
export const DeleteEmailTemplate = gql`
    mutation DeleteEmailTemplate(
        $emailType: EmailType, 
        $isInternalRole: Boolean, 
        $roleId: String,
        $selectedUserId: String
    ){
        deleteEmailTemplate(
            emailType: $emailType, 
            isInternalRole: $isInternalRole,  
            roleId: $roleId, 
            selectedUserId: $selectedUserId
        )
    }
`;
  
export const UpdateSplashScreenSubscription = gql`
    subscription updateSplashScreenSubscription{
        splashScreenNotification{
            id,
            description,
            message,
            status
        }
    }
`;

export const EmailTemplateQuery = gql`
    query EmailTemplate(
        $isInternalRole: Boolean, 
        $roleId: String, 
        $selectedUserId: String, 
        $emailType: EmailType
    ) {
        templates {
        emailTemplate(
            isInternalRole: $isInternalRole, 
            roleId: $roleId, 
            selectedUserId: $selectedUserId, 
            emailType: $emailType
        )   {
                emailTemplateGuid
                emailType
                isInternalRole
                roleGuid
                subject
                template
                emailStyle
                userGuid
                ccAssistant
                matchedPropertys {
                    entity
                    matchedProperty {
                        key
                        keyDescription
                        recipient
                        recipientDescription
                        scope
                        scopeDescription
                        source
                      	sourceDescription
                        location
                        locationDescription
                        assignee
                        assigneeDescription
                    }
                }
            }
        }
    }
`;

export const subscriptionEmailTemplate = gql`
    subscription SubscriptionEmailTemplate{
        emailTemplateNotification{
            id,
            message,
            status,
            emailType
        }
    }
`;

export interface EmailTemplateListQueryParams {
    emailType: string | null;
    integrationTemplateId: string | null;
}

export interface EmailTemplateListQueryData {
    loading?: boolean;
    networkStatus?: number;
    templates: Templates;
}

export interface Templates {
    emailTemplateList: EmailTemplateList[];
}

export interface EmailTemplateList {
    emailTemplateGuid:       string;
    emailType:               number;
    isInternalRole:          null;
    roleGuid:                null;
    subject:                 string;
    template:                string;
    emailStyle:              number;
    userGuid:                null;
    ccAssistant:             boolean;
    integrationTemplateId:   null;
    integrationTemplateName: null;
    description:             string;
    name:                    string;
    matchedPropertys:        MatchedPropertys;
    isActive:                boolean;
}

export function retrieveEmailTemplateListData(
    query: EmailTemplateListQueryParams, 
    refreshData: boolean,
    onSuccess: (data: EmailTemplateListQueryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
     : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
        client.query({
            query: EmailTemplateListQuery,
            variables: {
                emailType: query.emailType,
                integrationTemplateId: query.integrationTemplateId
            },
            fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { 
        onError(reason); 
    });
}

export const EmailTemplateListQuery = gql`
    query EmailTemplateList($emailType: EmailType, $integrationTemplateId: String) {
        templates {
            emailTemplateList(emailType:$emailType, integrationTemplateId: $integrationTemplateId){
                emailTemplateGuid
                emailType
                isInternalRole
                roleGuid
                subject
                template
                emailStyle
                userGuid
                ccAssistant
                integrationTemplateId,
                integrationTemplateName
                description
                name
                isActive
                matchedPropertys {
                    entity
                    matchedProperty {
                        key
                        keyDescription
                        recipient
                        recipientDescription
                        scope
                        scopeDescription
                    }
                }
            }
        }
    }
`;