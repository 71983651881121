import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';
import { RegionSettingsData } from '../firmSettingConfiguration/tabs/RegionalTab';
import { EnquiryData } from './models/Enquiry';

export enum IncludeEnum {
    All = 0,
    Open = 1,
    Closed = 2,
}

export enum GroupByType {
    status = 0,
    assignedTo,
    areaOfLaw,
    dueDates,
    team,
}

export interface AppUserData {
    loading?: boolean;
    networkStatus?: number;
    appUser: AppUser;
}

export interface AppUser {
    isAdministrator: boolean;
    userId: string;
    name: string;
    userName: string;
}

export interface OptionsData {
    loading?: boolean;
    networkStatus?: number;
    data: OptionData;
}

export interface OptionData {
    options: Option;
}

export interface Option {
    homeCountry: HomeCountry;
}

export interface HomeCountry {
    stringValue: string;
}

export interface TenantsData {
    loading?: boolean;
    networkStatus?: number;
    data: TenantData;
}

export interface TenantData {
    tenant: Tenant;
}

export interface Tenant {
    tenantId: string;
    sourceSystem: string;
    useVerificationLinkForNewUsers: boolean;
}

export interface EnquirySummaryQueryParams {
    offset: number | null;
    first: number | null;
    filter?: string | null;
    emailAddress?: string | null;
    isIncludeCurrent: boolean | null;
    isIncludeClosed: boolean | null;
    isMyEnquiriesOnly: boolean | null;
    sortColumn?: string | null;
    sortDirection?: string | null;
    groupBy: string | null;
    groupByValue: string | null;
    followUpDue: string | null;
    newEnquiry: string | null;
    assignedTo: string | null;
    areaOfLaw: string | null;
    teamList: string | null;
    isKeyOpportunity: boolean | null;
    enquiryQuality: string | null;
    enquiryLikelihoodToProceed: string | null;
    meetingWith: string | null;
    status: string | null;
    sourceList: string | null;
    location: string | null;
}

export interface EnquiryDetailsQueryParams {
    guid: string;
}

export function retrieveEnquirySummaryData(
    query: EnquirySummaryQueryParams,
    refreshData: boolean,
    onSuccess: (data: EnquiryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: EnquirySummaryListData,
            variables: {
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                emailAddress: query.emailAddress,
                isIncludeCurrent: query.isIncludeCurrent,
                isIncludeClosed: query.isIncludeClosed,
                isMyEnquiriesOnly: query.isMyEnquiriesOnly,
                sortColumn: query.sortColumn,
                sortDirection: query.sortDirection,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                followUpDue: query.followUpDue,
                newEnquiry: query.newEnquiry,
                assignedTo: query.assignedTo,
                areaOfLaw: query.areaOfLaw,
                teamList: query.teamList,
                isKeyOpportunity: query.isKeyOpportunity,
                enquiryQuality: query.enquiryQuality,
                enquiryLikelihoodToProceed: query.enquiryLikelihoodToProceed,
                meetingWith: query.meetingWith,
                status: query.status,
                sourceList: query.sourceList,
                location: query.location,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export function retrieveEnquiryDetailsData(
    query: EnquiryDetailsQueryParams,
    refreshData: boolean,
    onSuccess: (data: EnquiryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: EnquiryDetailsQuery,
            variables: {
                guid: query.guid,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const EnquirySummaryListData = gql`
    query EnquirySummaryListData(
        $offset: Int
        $first: Int
        $filter: String
        $emailAddress: String
        $isIncludeCurrent: Boolean
        $isIncludeClosed: Boolean
        $isMyEnquiriesOnly: Boolean
        $sortColumn: String
        $sortDirection: String
        $groupBy: String
        $groupByValue: String
        $followUpDue: String
        $newEnquiry: String
        $assignedTo: String
        $areaOfLaw: String
        $teamList: String
        $isKeyOpportunity: Boolean
        $enquiryQuality: String
        $enquiryLikelihoodToProceed: String
        $meetingWith: String
        $status: String
        $sourceList: String
        $location: String
    ) {
        enquiry {
            summaryList(
                offset: $offset
                first: $first
                filter: $filter
                emailAddress: $emailAddress
                isIncludeCurrent: $isIncludeCurrent
                isIncludeClosed: $isIncludeClosed
                isMyEnquiriesOnly: $isMyEnquiriesOnly
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                groupBy: $groupBy
                groupByValue: $groupByValue
                followUpDue: $followUpDue
                newEnquiry: $newEnquiry
                assignedTo: $assignedTo
                areaOfLaw: $areaOfLaw
                teamList: $teamList
                isKeyOpportunity: $isKeyOpportunity
                enquiryQuality: $enquiryQuality
                enquiryLikelihoodToProceed: $enquiryLikelihoodToProceed
                meetingWith: $meetingWith
                status: $status
                sourceList: $sourceList,
              	location: $location
            ) {
                recordCount
                actualRowCountForSearch

                summaries {
                    id
                    guid
                    createdDate
                    lastUpdated
                    isClosed
                    status
                    name
                    firstName
                    lastName
                    email
                    gender
                    initialEnquiryDate
                    phone
                    mobile
                    sourceGuidID
                    source
                    officeGuidID
                    office
                    sourceNotes
                    referrerName
                    organisation
                    positionAtOrganisation
                    natureOfEnquiry
                    practiceAreaGuidID
                    areaOfLaw
                    dateAssigned
                    assignedTo
                    assignedToName
                    assignedToEmailAddress
                    assignedBy
                    assignedByName
                    assignedByEmailAddress
                    followUpDueDate
                    followUpActionGuidID
                    followUpAction
                    followUpCompletedDate
                    followUpNotes
                    outcomeGuidID
                    outcome
                    outcomeDate
                    reasonGuidID
                    reason
                    internalNotes
                    internalNotesGuidID
                    isKeyOpportunity
                    createdByName
                    isMarketingConsent
                    isPrivacyStatementProvided
                    isConflictCheckPerformed
                    isIdentityCheckPerformed
                    isTermsAndConditionsApproved
                    isNoConflict
                    estimatedFees
                    estimatedDisbursements
                    enquiryQuality
                    likelihoodToProceed
                    referredToFirmName
                    notProceedingNotes
                    matterClientFirstName
                    matterClientLastName
                    relationshipToTheEnquirer
                    mainAddressSuburb
                    mainAddressState
                    mainAddressPostCode
                    matterFileNumber
                    matterFeesBilled
                    enquiryNotes
                    outcomeNotes
                    assignedToTeam
                    daysTakenToFirstFollowUp
                    daysTakenToWonOrLoss
                    monthOfEnquiry
                    otherPartyFirstName
                    otherPartyLastName
                    otherPartyEmail
                    otherPartyPhone
                    otherPartyMobile
                    meetingBookingDate
                    hadMeetingDate
                    meetingStartDate
                    bookingStaffName
                    matterCreatedDate
                    meetingLocation
                    internalNotes
                    isRiskAssessed
                    riskLevel
                    riskNotes
                    secondClientFirstName
                    secondClientLastName
                    secondClientRelationshipToEnquirer
                    secondClientGender
                    secondClientSuburb
                    secondClientState
                    secondClientPostCode
                    secondClientIsMarketingConsent
                    secondClientIsTermsAndConditionsApproved
                    secondClientIsPrivacyStatementProvided
                    secondClientIsConflictCheckPerformed
                    secondClientIsIdentityCheckPerformed
                    secondClientIsNoConflict
                  	secondClientCountry
                  	secondClientAddressName
                  	secondClientFloor
                  	secondClientNumber
                  	secondClientStreet1,
                  	secondClientStreet2,
                  	secondClientDOB,
                  	secondClientPOBoxType,
                  	secondClientPOBox,
                  	secondClientPostalSuburb,
                  	secondClientPostalState,
                  	secondClientPostalPostCode,
                  	secondClientPostalCountry,
                    secondClientGuid
                    eventAndCampaignGuid
                    eventAndCampaignName
                    eventAndCampaignNotesGuid
                    eventAndCampaignNotes
                }
            }
        }
    }
`;

const EnquiryDetailsQuery = gql`
    query EnquiryDetailsData($guid: String) {
        enquiry {
            detail(guid: $guid) {
                guid
                createdDate
                createdByName
                lastUpdated
                isClosed                
                isContactCreated
                isMatterCreated
                firstName
                lastName
                email
                gender
                initialEnquiryDate
                phone
                mobile
                sourceGuidID
                source
                isSourceReferrer
                officeGuidID
                office
                sourceNotes
                organisation
                positionAtOrganisation
                natureOfEnquiry
                practiceAreaGuidID
                areaOfLaw
                dateAssigned
                assignedTo
                assignedToName
                assignedToEmailAddress
                assignedBy
                assignedByName
                assignedByEmailAddress
                assignmentReminderOffset
                followUpDueDate
                followUpActionGuidID
                followUpAction
                followUpDate
                followUpNotes
                outcomeGuidID
                outcome
                outcomeDate
                reasonGuidID
                reason
                internalNotes
                internalNotesGuidID
                isKeyOpportunity
                referrerGuid
                referrerName
                nameGuid
                nameTitle
                organisationGuid
                clientRelationshipToEnquirer
                isMarketingConsent
                isTermsAndConditionsApproved
                isPrivacyStatementProvided
                isConflictCheckPerformed
                isIdentityCheckPerformed
                isNoConflict
                estimatedFees
                isEstimatedFeesIncludesTax
                estimatedDisbursements
                isEstimatedDisbursementsIncludesTax
                enquiryQuality
                likelihoodToProceed
                dOB
                clientFirstName
                clientLastName
                addressName
                floor
                number
                street
                clientAddressStreet2
                suburb
                state
                postcode
                country
                pOBoxType
                pOBox
                postalSuburb
                postalState
                postalPostCode
                postalCountry
                clientRelationshipToEnquirer
                files {
                    guidID
                    name
                    url
                }
                matterId
                matterGuid
                matterTitle
                matterFileNumber
                assignedToIsStaff
                assignedToStaffGuid
                assignedToStaffName
                enquiryNotes
                integrationContactId
                integrationClientId
                integrationOtherPartyId
                integrationMatterId
                integrationMatterDocumentId

                otherPartyFirstName
                otherPartyLastName
                otherPartyNotes
                otherPartyEmail
                otherPartyPhone
                otherPartyMobile
                otherPartyOrganisation
            
                otherPartyDOB
                otherPartyAddressName
                otherPartyFloor
                otherPartyNumber
                otherPartyStreet
                otherPartyStreet2
                otherPartySuburb
                otherPartyState
                otherPartyPostcode
                otherPartyCountry

                otherPartyPOBoxType
                otherPartyPOBox
                otherPartyPostalSuburb
                otherPartyPostalState
                otherPartyPostalPostCode
                otherPartyPostalCountry
                otherPartyNameGuid
                otherPartyOrganisationGuid
                
                isRiskAssessed
                riskLevelGuid
                riskLevel
                riskNotes

                secondClientFirstName
                secondClientLastName
                secondClientRelationshipToEnquirer
                secondClientGender
                secondClientSuburb
                secondClientState
                secondClientPostCode
                secondClientIsMarketingConsent
                secondClientIsTermsAndConditionsApproved
                secondClientIsPrivacyStatementProvided
                secondClientIsConflictCheckPerformed
                secondClientIsIdentityCheckPerformed
                secondClientIsNoConflict

                secondClientCountry
                secondClientAddressName
                secondClientFloor
                secondClientNumber
                secondClientStreet1,
                secondClientStreet2,
                secondClientDOB,

                secondClientPOBoxType,
                secondClientPOBox,
                secondClientPostalSuburb,
                secondClientPostalState,
                secondClientPostalPostCode,
                secondClientPostalCountry,
                secondClientGuid

                responseText
                eventAndCampaignGuid
                eventAndCampaignName
                eventAndCampaignNotesGuid
                eventAndCampaignNotes

                isNameFromDataSource
                isOrganisationFromDataSource
                isOtherSideFromDataSource
                isSecondClientFromDataSource
            
                title
                otherPartyTitle
        
                clientTitle
                clientGuid
                clientEmail
                clientPhone
                clientMobile
        
                secondClientTitle
                secondClientEmail
                secondClientPhone
                secondClientMobile
            
            }
            actions(guid: $guid) {
                canModify
                canAssign
                canFollowUp
                canCreateClient
                canEmailAssignee
                canReOpen
                canCreateContactForLostEnquiry
                canMessageAssignee
                canLinkMatter
                canChangeLinkedMatter
                canCreateMatter
            }
        }
    }
`;

export const CreateEnquiryMutation = gql`
    mutation CreateEnquiry($input: CreateEnquiryInput!) {
        createEnquiry(input: $input) {
            status
            error
        }
    }
`;

export const DeleteEnquiryMutation = gql`
    mutation DeleteEnquiry($guid: String!) {
        deleteEnquiry(guid: $guid) {
            status
            error
        }
    }
`;

export const UpdateEnquiryMutation = gql`
    mutation UpdateEnquiry($input: UpdateEnquiryInput!) {
        updateEnquiry(input: $input) {
            status
            error
        }
    }
`;

export const AssignStaffToEnquiryMutation = gql`
    mutation AssignStaffToEnquiry($input: AssignStaffToEnquiryInput!) {
        assignStaffToEnquiry(input: $input) {
            status
            error
        }
    }
`;

export const FollowUpEnquiryMutation = gql`
    mutation FollowUpEnquiry($input: FollowUpEnquiryInput!) {
        followUpEnquiry(input: $input) {
            status
            error
        }
    }
`;

export const LinkEnquiryMutation = gql`
    mutation ($input: LinkMatterToEnquiryInput!) {
        linkMatterToEnquiry(input: $input) {
            status
            error
        }
    }
`;

export const CreateMeetingBookingTaskMutation = gql`
    mutation CreateMeetingBookingMutation($input: BookMeetingInput) {
        createMeetingBooking(input: $input) {
            error
            status
        }
    }
`;

export const CreateClientAndContactMutation = gql`
    mutation CreateClientAndContact($input: CreateClientAndContactInput!) {
        createClientAndContact(input: $input) {
            status
            error
        }
    }
`;

export const CreateClientContactMutation = gql`
    mutation CreateClientContact($input: CreateClientContactInput!) {
        createClientContact(input: $input) {
            status
            error
        }
    }
`;

export const CreateContactMutation = gql`
    mutation CreateContact($input: CreateContactInput!) {
        createContact(input: $input) {
            status
            error
        }
    }
`;

export const CompleteCreateClientMutation = gql`
    mutation CompleteCreateClient($input: CompleteCreateClientInput!) {
        completeCreateClient(input: $input) {
            status
            error
        }
    }
`;

export const CreateEnquirySubscription = gql`
    subscription CreateEnquirySubscription {
        createEnquiryNotification {
            id
            description
            status
            message
            enquiryDetail {
                guid
                createdDate
                lastUpdated
                isClosed
                firstName
                lastName
                email
                gender
                initialEnquiryDate
                phone
                mobile
                sourceGuidID
                source
                officeGuidID
                office
                sourceNotes
                organisation
                positionAtOrganisation
                natureOfEnquiry
                practiceAreaGuidID
                areaOfLaw
                dateAssigned
                assignedTo
                assignedToName
                assignedToEmailAddress
                assignedBy
                assignedByName
                assignedByEmailAddress
                assignmentReminderOffset
                followUpDueDate
                followUpActionGuidID
                followUpAction
                followUpDate
                followUpNotes
                outcomeGuidID
                outcome
                outcomeDate
                reasonGuidID
                reason
                internalNotes
                internalNotesGuidID
                isKeyOpportunity
                referrerGuid
                referrerName
            }
        }
    }
`;

export const UpdateEnquirySubscription = gql`
    subscription UpdateEnquirySubscription {
        updateEnquiryNotification {
            id
            description
            status
            message
            enquiryDetail {
                guid
                createdDate
                lastUpdated
                isClosed
                firstName
                lastName
                email
                gender
                initialEnquiryDate
                phone
                mobile
                sourceGuidID
                source
                officeGuidID
                office
                sourceNotes
                organisation
                positionAtOrganisation
                natureOfEnquiry
                practiceAreaGuidID
                areaOfLaw
                dateAssigned
                assignedTo
                assignedToName
                assignedToEmailAddress
                assignedBy
                assignedByName
                assignedByEmailAddress
                assignmentReminderOffset
                followUpDueDate
                followUpActionGuidID
                followUpAction
                followUpDate
                followUpNotes
                outcomeGuidID
                outcome
                outcomeDate
                reasonGuidID
                reason
                internalNotes
                internalNotesGuidID
                isKeyOpportunity
                referrerGuid
                referrerName
            }
        }
    }
`;

export const DeleteEnquirySubscription = gql`
    subscription DeleteEnquirySubscription {
        deleteEnquiryNotification {
            id
            description
            status
            message
            guid
        }
    }
`;

export const FollowUpEnquiryNotification = gql`
    subscription followUpEnquirySubscription {
        followUpEnquiryNotification {
            id
            status
            description
            message
            enquiryDetail {
                guid
                createdDate
                lastUpdated
                isClosed
                firstName
                lastName
                email
                gender
                initialEnquiryDate
                phone
                mobile
                sourceGuidID
                source
                officeGuidID
                office
                sourceNotes
                organisation
                positionAtOrganisation
                natureOfEnquiry
                practiceAreaGuidID
                areaOfLaw
                dateAssigned
                assignedTo
                assignedToName
                assignedToEmailAddress
                assignedBy
                assignedByName
                assignedByEmailAddress
                assignmentReminderOffset
                followUpDueDate
                followUpActionGuidID
                followUpAction
                followUpDate
                followUpNotes
                outcomeGuidID
                outcome
                outcomeDate
                reasonGuidID
                reason
                internalNotes
                internalNotesGuidID
                isKeyOpportunity
                referrerGuid
                referrerName
            }
        }
    }
`;

export const AssignEnquiryToStaffNotification = gql`
    subscription assignEnquiryToStaffNotification {
        assignEnquiryToStaffNotification {
            id
            status
            description
            message
            enquiryDetail {
                guid
                createdDate
                lastUpdated
                isClosed
                firstName
                lastName
                email
                gender
                initialEnquiryDate
                phone
                mobile
                sourceGuidID
                source
                officeGuidID
                office
                sourceNotes
                organisation
                positionAtOrganisation
                natureOfEnquiry
                practiceAreaGuidID
                areaOfLaw
                dateAssigned
                assignedTo
                assignedToName
                assignedToEmailAddress
                assignedBy
                assignedByName
                assignedByEmailAddress
                assignmentReminderOffset
                followUpDueDate
                followUpActionGuidID
                followUpAction
                followUpDate
                followUpNotes
                outcomeGuidID
                outcome
                outcomeDate
                reasonGuidID
                reason
                internalNotes
                internalNotesGuidID
                isKeyOpportunity
                referrerGuid
                referrerName
            }
        }
    }
`;

export const CreateClientAndContactNotification = gql`
    subscription createClientAndContactNotification {
        createClientAndContactNotification {
            id
            status
            message
            description
        }
    }
`;

export const CreateClientContactNotification = gql`
    subscription createClientContactNotification {
        createClientContactNotification {
            id
            status
            message
            description
        }
    }
`;

export const CreateContactNotification = gql`
    subscription createContactNotification {
        createContactNotification {
            id
            status
            message
            description
        }
    }
`;

export const CompleteCreateClientNotification = gql`
    subscription completeCreateClientNotification {
        completeCreateClientNotification {
            id
            status
            message
            description
        }
    }
`;

const EnquiryActionsData = gql`
    query EnquiryActionsData($guid: String) {
        enquiry {
            detail(guid: $guid) {
                guid
                createdDate
                createdByName
                lastUpdated
                isClosed
                firstName
                lastName
                name
                email
                gender
                initialEnquiryDate
                phone
                mobile
                sourceGuidID
                source
                isSourceReferrer
                officeGuidID
                office
                sourceNotes
                organisation
                positionAtOrganisation
                natureOfEnquiry
                practiceAreaGuidID
                areaOfLaw
                dateAssigned
                assignedTo
                assignedToName
                assignedToEmailAddress
                assignedBy
                assignedByName
                assignedByEmailAddress
                assignmentReminderOffset
                followUpDueDate
                followUpActionGuidID
                followUpAction
                followUpDate
                followUpNotes
                outcomeGuidID
                outcome
                outcomeDate
                reasonGuidID
                reason
                internalNotes
                internalNotesGuidID
                isKeyOpportunity
                referrerGuid
                referrerName
                nameGuid
                nameTitle
                organisationGuid
                clientRelationshipToEnquirer
                isMarketingConsent
                isTermsAndConditionsApproved
                isPrivacyStatementProvided
                isConflictCheckPerformed
                isIdentityCheckPerformed
                isNoConflict
                estimatedFees
                isEstimatedFeesIncludesTax
                estimatedDisbursements
                isEstimatedDisbursementsIncludesTax
                enquiryQuality
                likelihoodToProceed
                dOB
                clientFirstName
                clientLastName
                addressName
                floor
                number
                street
                clientAddressStreet2
                suburb
                state
                postcode
                country
                pOBoxType
                pOBox
                postalSuburb
                postalState
                postalPostCode
                postalCountry
                clientRelationshipToEnquirer
                files {
                    guidID
                    name
                    url
                }
                matterId
                matterGuid
                matterTitle
                matterFileNumber
                assignedToIsStaff
                assignedToStaffGuid
                assignedToStaffName
                otherPartyFirstName
                otherPartyLastName
                otherPartyNotes
                otherPartyEmail
                otherPartyPhone
                otherPartyMobile
                otherPartyOrganisation
                enquiryNotes
                integrationContactId
                integrationClientId
                integrationOtherPartyId
                integrationMatterId
                integrationMatterDocumentId

                otherPartyDOB
                otherPartyAddressName
                otherPartyFloor
                otherPartyNumber
                otherPartyStreet
                otherPartyStreet2
                otherPartySuburb
                otherPartyState
                otherPartyPostcode
                otherPartyCountry

                otherPartyPOBoxType
                otherPartyPOBox
                otherPartyPostalSuburb
                otherPartyPostalState
                otherPartyPostalPostCode
                otherPartyPostalCountry
                otherPartyNameGuid
                otherPartyOrganisationGuid
                
                isRiskAssessed
                riskLevelGuid
                riskLevel
                riskNotes

                secondClientFirstName
                secondClientLastName
                secondClientRelationshipToEnquirer
                secondClientGender
                secondClientSuburb
                secondClientState
                secondClientPostCode
                secondClientIsMarketingConsent
                secondClientIsTermsAndConditionsApproved
                secondClientIsPrivacyStatementProvided
                secondClientIsConflictCheckPerformed
                secondClientIsIdentityCheckPerformed
                secondClientIsNoConflict

                secondClientCountry
                secondClientAddressName
                secondClientFloor
                secondClientNumber
                secondClientStreet1,
                secondClientStreet2,
                secondClientDOB,

                secondClientPOBoxType,
                secondClientPOBox,
                secondClientPostalSuburb,
                secondClientPostalState,
                secondClientPostalPostCode,
                secondClientPostalCountry,
                secondClientGuid
            }
            actions(guid: $guid) {
                canModify
                canAssign
                canFollowUp
                canCreateClient
                canEmailAssignee
                canReOpen
                canCreateContactForLostEnquiry
                canMessageAssignee
                canLinkMatter
                canChangeLinkedMatter
                canCreateMatter
            }
        }
    }
`;

export function fetchEnquiryDetailsData(
    refreshData: boolean,
    guid: string,
    onSuccess: (data?: EnquiryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: EnquiryActionsData,
            variables: { guid: guid },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export function fetchAppUserData(
    refreshData: boolean,
    onSuccess: (data?: AppUserData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: UserData,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export function fetchHomeCountry(
    refreshData: boolean,
    onSuccess: (data?: OptionData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: HomeCountryData,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export function fetchTenantData(
    refreshData: boolean,
    onSuccess: (data?: TenantData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: TenantDataQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export function fetchEnquiryScope(
    refreshData: boolean,
    onSuccess: (data?: EnquiryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: EnquiryScope,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export function fetchTenantTimezoneData(
    refreshData: boolean,
    onSuccess: (data?: RegionSettingsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: TenantTimezoneDataQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export const EnquiryScope = gql`
    query enquiryScope {
        enquiry {
            scope {
                showMyEnquiriesOnly
                myEnquiriesOnlySelected
            }
        }
    }
`;

export const UserData = gql`
    query AppUser {
        appUser {
            isAdministrator
            name
            userId
            userName
        }
    }
`;

export const HomeCountryData = gql`
    query homeCountry {
        options {
            homeCountry {
                stringValue
            }
        }
    }
`;

export const TenantDataQuery = gql`
    query Tenant {
        tenant {
            sourceSystem
            useVerificationLinkForNewUsers
        }
    }
`;

export const TenantTimezoneDataQuery = gql`
    query tenantTimezone {
        settings {
            id
            systems {
                id
                regionSettings {
                    id
                    config {
                        id
                        timeZone
                        timeZoneDescription
                        baseUtcOffset
                    }
                }
            }
        }
    }
`;

export interface EnquiryStatusesParams extends EnquirySummaryQueryParams {
    isIncludeCount: boolean | null;
}

export function retrieveEnquiryStatusesData(
    query: EnquiryStatusesParams,
    refreshData: boolean,
    onSuccess: (data: EnquiryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: EnquiryStatuses,
            variables: {
                isIncludeCount: query.isIncludeCount,
                offset: null,
                first: null,
                filter: query.filter,
                isIncludeCurrent: query.isIncludeCurrent,
                isIncludeClosed: query.isIncludeClosed,
                isMyEnquiriesOnly: query.isMyEnquiriesOnly,
                sortColumn: null,
                sortDirection: null,
                groupBy: null,
                groupByValue: null,
                followUpDue: query.followUpDue,
                newEnquiry: query.newEnquiry,
                assignedTo: query.assignedTo,
                areaOfLaw: query.areaOfLaw,
                teamList: query.teamList,
                isKeyOpportunity: query.isKeyOpportunity,
                enquiryQuality: query.enquiryQuality,
                enquiryLikelihoodToProceed: query.enquiryLikelihoodToProceed,
                meetingWith: query.meetingWith,
                status: query.status,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export const EnquiryStatuses = gql`
    # Write your query or mutation here
    query enquiryStatuses(
        $isIncludeCount: Boolean
        $offset: Int
        $first: Int
        $filter: String
        $isIncludeCurrent: Boolean
        $isIncludeClosed: Boolean
        $isMyEnquiriesOnly: Boolean
        $sortColumn: String
        $sortDirection: String
        $groupBy: String
        $groupByValue: String
        $followUpDue: String
        $newEnquiry: String
        $assignedTo: String
        $areaOfLaw: String
        $teamList: String
        $isKeyOpportunity: Boolean
        $enquiryQuality: String
        $enquiryLikelihoodToProceed: String
        $meetingWith: String
        $status: String
        $sourceList: String
        $location: String
    ) {
        enquiry {
            statuses(
                isIncludeCount: $isIncludeCount
                offset: $offset
                first: $first
                filter: $filter
                isIncludeCurrent: $isIncludeCurrent
                isIncludeClosed: $isIncludeClosed
                isMyEnquiriesOnly: $isMyEnquiriesOnly
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                groupBy: $groupBy
                groupByValue: $groupByValue
                followUpDue: $followUpDue
                newEnquiry: $newEnquiry
                assignedTo: $assignedTo
                areaOfLaw: $areaOfLaw
                teamList: $teamList
                isKeyOpportunity: $isKeyOpportunity
                enquiryQuality: $enquiryQuality
                enquiryLikelihoodToProceed: $enquiryLikelihoodToProceed
                meetingWith: $meetingWith
                status: $status
                sourceList: $sourceList,
                location: $location
            ) {
                guid
                description
                count
            }
        }
    }
`;

export interface FirmData {
    loading?: boolean;
    networkStatus?: number;
    firm: Firm;
}

export interface Firm {
    teams: Teams;
}

export interface Teams {
    team: Team[];
}

export interface Team {
    id: number;
    guidID: string;
    description: string;
    staffCount: number;
    taskCount: number;
    enquiryCount: number;
}

export interface EnquiryFirmTeamParams extends EnquirySummaryQueryParams {
    isIncludeEnquiryCount: boolean | null;
    onlyUsedByEnquiries: boolean | null;
}

export function retrieveFirmTeamData(
    query: EnquiryFirmTeamParams,
    refreshData: boolean,
    onSuccess: (data: FirmData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: FirmTeams,
            variables: {
                isIncludeEnquiryCount: query.isIncludeEnquiryCount,

                offset: null,
                first: null,
                filter: query.filter,
                isIncludeCurrent: query.isIncludeCurrent,
                isIncludeClosed: query.isIncludeClosed,
                isMyEnquiriesOnly: query.isMyEnquiriesOnly,
                sortColumn: null,
                sortDirection: null,
                groupBy: null,
                groupByValue: null,
                followUpDue: query.followUpDue,
                newEnquiry: query.newEnquiry,
                assignedTo: query.assignedTo,
                areaOfLaw: query.areaOfLaw,
                teamList: query.teamList,
                isKeyOpportunity: query.isKeyOpportunity,
                enquiryQuality: query.enquiryQuality,
                enquiryLikelihoodToProceed: query.enquiryLikelihoodToProceed,
                onlyUsedByEnquiries: query.onlyUsedByEnquiries,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export const FirmTeams = gql`
    query firmTeams(
        $isIncludeEnquiryCount: Boolean
        $offset: Int
        $first: Int
        $filter: String
        $isIncludeCurrent: Boolean
        $isIncludeClosed: Boolean
        $isMyEnquiriesOnly: Boolean
        $sortColumn: String
        $sortDirection: String
        $groupBy: String
        $groupByValue: String
        $followUpDue: String
        $newEnquiry: String
        $assignedTo: String
        $areaOfLaw: String
        $teamList: String
        $isKeyOpportunity: Boolean
        $enquiryQuality: String
        $onlyUsedByEnquiries: Boolean
        $enquiryLikelihoodToProceed: String
    ) {
        firm {
            teams(
                isIncludeEnquiryCount: $isIncludeEnquiryCount
                offset: $offset
                first: $first
                filter: $filter
                isIncludeCurrent: $isIncludeCurrent
                isIncludeClosed: $isIncludeClosed
                isMyEnquiriesOnly: $isMyEnquiriesOnly
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                groupBy: $groupBy
                groupByValue: $groupByValue
                followUpDue: $followUpDue
                newEnquiry: $newEnquiry
                assignedTo: $assignedTo
                areaOfLaw: $areaOfLaw
                teamList: $teamList
                isKeyOpportunity: $isKeyOpportunity
                enquiryQuality: $enquiryQuality
                enquiryLikelihoodToProceed: $enquiryLikelihoodToProceed
                onlyUsedByEnquiries: $onlyUsedByEnquiries
            ) {
                team {
                    id
                    guidID
                    description
                    enquiryCount
                }
            }
        }
    }
`;

export interface EmailTemplateCountQueryParams {
    emailType: string | null;
    areaOfLaw: string;
}

export interface EmailTemplateData {
    loading?: boolean;
    networkStatus?: number;
    templates: Templates;
}

export interface Templates {
    emailTemplateCount: EmailTemplateCount;
}

export interface EmailTemplateCount {
    count: number;
}

export function retrieveEmailTemplateCount(
    query: EmailTemplateCountQueryParams,
    refreshData: boolean,
    onSuccess: (data: EmailTemplateData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: EmailTemplateCountData,
            variables: {
                emailType: query.emailType,
                areaOfLaw: query.areaOfLaw,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const EmailTemplateCountData = gql`
    query EmailTemplateCount($emailType: EmailType, $areaOfLaw: String) {
        templates {
            emailTemplateCount(emailType: $emailType, areaOfLaw: $areaOfLaw) {
                count
            }
        }
    }
`;

export interface EnquiryDefaultSettings {
    loading?: boolean;
    networkStatus?: number;
    settings: Settings;
}

export interface Settings {
    systems: Systems;
}

export interface Systems {
    enquiryDefaults: EnquiryDefaults;
}

export interface EnquiryDefaults {
    config: EnquiryDefaultConfig;
}

export interface EnquiryDefaultConfig {
    defaultMeetingLength: String;
    isCalculatedDays: Boolean;
    isCalculatedHours: Boolean;
    isManualEntry: Boolean;
    addDays: number;
    addHours: number;
    timeOfDay: String;
    reminder: string;
    overdueReminder: string;
    isLocationFieldMandatory: boolean;
}

export function retrieveEnquiryDefaultSettings(
    refreshData: boolean,
    onSuccess: (data: EnquiryDefaultSettings) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: EnquirySettingsData,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const EnquirySettingsData = gql`
    query enquirySettings {
        settings {
            id
            systems {
                id
                enquiryDefaults {
                    id
                    config {
                        id
                        defaultMeetingLength
                        isCalculatedDays
                        isCalculatedHours
                        isManualEntry
                        addDays
                        addHours
                        timeOfDay
                        reminder
                        overdueReminder
                        isLocationFieldMandatory
                    }
                }
            }
        }
    }
`;

export interface GeneralInfoDataParams {
    id: number;
}
export interface GeneralInfoData {
    matter: Matter;
}

export interface Matter {
    id: number;
    matterCategory: string;
    country: null;
    family: null;
    fileLocation: null;
    fileNumber: string;
    firbStatus: null;
    lastUpdate: Date;
    matterType: string;
    property: null;
    propertyLabel: null;
    stage: null;
    state: string;
    status: string;
    description: string;
    title: string;
    images: Images;
    client: string;
    nameID: number;
    financials: Financials;
}

export interface Financials {
    estimatedCosts: number;
    billedTimeAndFees: number;
    billedDisbursements: number;
    unbilledTimeAndFees: number;
    unbilledDisbursements: number;
    balanceDue: number;
    trustDeposits: number;
    trustWithdrawals: number;
    trustGeneralBalance: number;
    trustControlledMoneyBalance: number;
    trustInvestmentBalance: number;
    billingMethod: string;
    costAgreementReceived: string;
    agedDebtorCurrent: number;
    agedDebtor30Days: number;
    agedDebtor60Days: number;
    agedDebtor90Days: number;
}

export interface Images {
    recordCount: number;
    // tslint:disable-next-line: no-any
    image: any[];
}

export function retrieveGeneralInfoData(
    query: GeneralInfoDataParams,
    refreshData: boolean,
    onSuccess: (data: GeneralInfoData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: GENERAL_INFO_QUERY,
            variables: {
                id: query.id,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const GENERAL_INFO_QUERY = gql`
    query GeneralInfoData($id: Int!) {
        matter(id: $id) {
            id
            matterCategory
            country
            family
            fileLocation
            fileNumber
            firbStatus
            lastUpdate
            matterType
            property
            propertyLabel
            stage
            state
            status
            description
            title
            client
            nameID
            id
            financials {
                estimatedCosts
                billedTimeAndFees
                billedDisbursements
                unbilledTimeAndFees
                unbilledDisbursements
                balanceDue
                trustDeposits
                trustWithdrawals
                trustGeneralBalance
                trustControlledMoneyBalance
                trustInvestmentBalance
                billingMethod
                costAgreementReceived
                agedDebtorCurrent
                agedDebtor30Days
                agedDebtor60Days
                agedDebtor90Days
            }
        }
    }
`;

export interface UpdateEnquiryQualityParams {
    guidId: string | null;
    enquiryQuality: number | null;
    isKeyOpportunity: boolean | null;
}

export const UpdateEnquiryQualityMutation = gql`
    mutation updateEnquiryQuality($guidId: String, $enquiryQuality: Int, $isKeyOpportunity: Boolean) {
        updateEnquiryQuality(guid: $guidId, enquiryQuality: $enquiryQuality, isKeyOpportunity: $isKeyOpportunity) {
            error
            status
        }
    }
`;

export interface UpdateLikelihoodParams {
    guidId: string | null;
    likelihoodToProceed: number | null;
    isKeyOpportunity: boolean | null;
}

export const UpdateEnquiryLikelihoodMutation = gql`
    mutation updateEnquiryLikelihood($guidId: String, $likelihoodToProceed: Int, $isKeyOpportunity: Boolean) {
        updateEnquiryLikelihood(guid: $guidId, likelihoodToProceed: $likelihoodToProceed, isKeyOpportunity: $isKeyOpportunity) {
            error
            status
        }
    }
`;
